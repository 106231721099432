<template>
  <div class="w-full">
    <progress-component></progress-component>
    <div
      class="home bg-slate-200 min-h-screen p-4 max-w-[100vw]"
      :class="{ loading }"
    >
      <div class="col-top w-full">
        <h3 class="flex justify-between items-center mb-4">
          <logo-component></logo-component>
          <input
            type="color"
            v-if="sites[currentSite]"
            :value="sites[currentSite].color"
            class="color w-[30px]"
            @change="changecolor"
            @input="changecolor"
          />
          <!-- <settings-component></settings-component> -->
        </h3>
        <profile-component
          @show_search="search = 1"
          class="mb-4 pr-10 md:pr-0"
        ></profile-component>
        <credits-component></credits-component>
        <div v-if="!isMobile || (isMobile && view == 1)" class="sites">
          <template v-if="Object.values(sites).length">
            <div
              class="site"
              v-for="(config, site) in sites"
              :key="site"
              :class="{
                posted: config.posted,
                postto: config.postTo,
              }"
            >
              <div class="site-info">
                <input
                  type="checkbox"
                  @change="saveData"
                  v-model="config.postTo"
                />
                <small
                  class="status"
                  :style="{ borderColor: config.color }"
                  :class="{
                    [`border-l-4`]: true,
                    active: currentSite == site,
                    token: config.token.length > 50,
                  }"
                  @click="getCategories(site, config, $event)"
                >
                  {{
                    site.replace("http://", "").replace("https://", "")
                  }}</small
                >
                <span class="rem" @click="removeSite(site)">x</span>
                <span
                  class="connect"
                  :class="{ active: config.token.length > 200 }"
                  @click="connect(site)"
                  ><font-awesome-icon icon="fa-solid fa-plug"
                /></span>
                <a :href="site" class="access" target="_blank"
                  ><font-awesome-icon icon="fa-solid fa-external-link"
                /></a>
                <a
                  :href="`${site}/wp-admin/admin-ajax.php?action=iaxpress_autologin&token=${config.token}&token_key=${config.token_key}`"
                  class="access"
                  target="_blank"
                  ><font-awesome-icon icon="fa-brands fa-wordpress-simple"
                /></a>
              </div>
              <div class="days">
                <div
                  class="day"
                  v-for="day in ultimos7Dias()"
                  :key="day"
                  :class="{
                    ['hasposts']: (config.counter[diaKey(day)] || 0) > 0,
                    ['error']: (config.counter[diaKey(day)] || 0) < 4,
                    ['weekend']: isWeekend(day),
                  }"
                >
                  <strong>{{ diasDaSemanaAbreviado(day) }}</strong>
                  <span>{{ config.counter[diaKey(day)] || 0 }}</span>
                </div>
              </div>
            </div>
          </template>
          <div class="flex gap-2 justify-between w-full">
            <button class="btn btn-small" @click="addsite = true" type="button">
              adicionar
            </button>
            <button
              class="btn btn-small export"
              @click="exportsites"
              type="button"
            >
              exportar
            </button>
            <button
              class="btn btn-small import"
              @click="importsites"
              type="button"
            >
              importar
            </button>
          </div>
          <download-component class="mt-4"></download-component>
          <div class="div mt-4 instructions flex flex-col justify-between">
            <p>
              Desenvolvido por
              <a
                class="font-bold"
                href="https://ersolucoesweb.com.br"
                target="_blank"
                >ER Soluções Web</a
              >
              - v{{ version }}
            </p>
            <ins class="adsbygoogle"
              style="display:block"
              data-ad-client="ca-pub-2603389161912203"
              data-ad-slot="1148973949"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
          </div>
        </div>
      </div>
      <div class="cols">
        <div
          class="w-full flex flex-col items-center mb-4 fixed top-0 left-0 h-screen p-4 md:p-8 bg-white z-50"
          v-if="search"
        >
          <button
            class="mb-4 cursor-pointer ml-auto bg-black text-white fixed top-0 right-0 text-[1.5em] w-[40px] h-[40px]"
            @click="search = 0"
          >
            X
          </button>
          <div class="w-full flex flex-col md:flex-row gap-4 items-center mb-4 mt-8">
            <select
              v-model="fontePosts"
              class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
            >
              <option value="">Selecione o site</option>
              <template v-for="(source, key) in sites_history" :key="source">
                <option class="py-2" :value="source.url">
                  {{ source.url }}
                </option>
              </template>
            </select>
            <div class="flex w-full justify-between md:w-auto gap-4 items-center">
              <button
                @click="buscarPosts"
                class="whitespace-nowrap w-full md:w-auto items-center text-white bg-green-700 text-center rounded-md px-4 uppercase text-[12px] font-bold h-[40px]"
              >
                buscar posts
              </button>
              <button @click="addSource">
                <font-awesome-icon icon="fa-solid fa-plus-circle" />
              </button>
              <button @click="removeSource">
                <font-awesome-icon icon="fa-solid fa-minus-circle" />
              </button>
            </div>
          </div>
          <div
            v-if="currentSource?.is_wordpress"
            class="w-full flex-wrap md:flex-nowrap flex mb-4 gap-4 items-center justify-between"
          >
            <select
              type="text"
              @change="getFonteCats"
              v-model="remote_cat"
              class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
              placeholder="Palavra chave"
            >
              <option value="">{{ fonteCatText }}</option>
              <template v-for="cat in remote_cats" :key="cat.id">
                <option :value="cat.id" v-html="cat.name"></option>
              </template>
            </select>
            <input
              type="text"
              v-model="remote_search"
              class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
              placeholder="Palavra chave"
            />
            <input
              type="date"
              v-model="remote_date_search"
              class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
              placeholder="Data"
            />
          </div>
          <div
            class="w-full flex overflow-y-auto h-[90vh] md:h-auto flex-col justify-start items-start gap-4 pb-2 mb-4 border-b-2"
            v-if="posts.length"
          >
            <div
              class="flex w-full flex-col md:flex-row justify-between items-center"
              v-for="post in posts"
              :key="post"
            >
              <template v-if="post">
                <span
                  @click="previewPost(post)"
                  class="text-[12px] text-normal"
                  >{{ decode(post.title.rendered) }}</span
                >
                <div class="flex w-full md:w-auto gap-4 mt-2 md:mt-0">
                  <button
                    @click="addToList(post)"
                    :class="{
                      'text-white': uselist[post.id],
                      'text-black': !uselist[post.id],
                      'bg-green-500': uselist[post.id],
                      'bg-gray-300': !uselist[post.id],
                    }"
                    class="px-4 py-1 rounded-md text-[11px] text-center justify-center w-full md:w-auto flex items-center gap-2"
                  >
                    {{ uselist[post.id] ? "selecionado" : "selecionar" }}
                    <span
                      v-if="post.id == currentPost?.id"
                      class="w-[5px] h-[5px] rounded-[10px] bg-white block"
                    ></span>
                  </button>
                  <button
                    @click="postThis(post, 1)"
                    class="px-4 py-1 rounded-md text-black bg-yellow-500 w-full md:w-auto text-[11px] text-center"
                  >
                    usar
                  </button>
                  <a
                    :href="post.link"
                    target="_blank"
                    class="px-4 py-1 cursor-pointer rounded-md text-white w-full md:w-auto bg-blue-500 text-[11px] text-center"
                    >ver</a
                  >
                </div>
              </template>
            </div>
          </div>
          <div class="flex justify-between w-full">
            <button
              v-if="Object.keys(uselist).length > 0"
              type="button"
              class="bg-yellow-400 py-1 w-full h-[50px] px-4 mt-4 text-black uppercase text-[14px] font-bold rounded-md"
              @click="postToAll"
            >
              {{
                hasSelected
                  ? "Postar nos sites selecionados"
                  : "Postar em todos os sites"
              }}
            </button>
          </div>
        </div>
        <div v-if="!isMobile || (isMobile && view == 3)" class="col-left mb-auto">
          <div class="title-container gap-4" v-if="sites[currentSite]">
            <input
              class="form-control"
              v-model="form.fonte"
              type="text"
              @keyup="syncEdit"
              placeholder="Fonte"
            />
            <button @click="importPost" class="flex justify-center items-center text-[1.3em] bg-purple-600 mb-4 rounded-md text-white h-[40px] px-6">
              <font-awesome-icon icon="fa-solid fa-file-import" />
            </button>
          </div>
          <div class="title-container gap-4 " v-if="sites[currentSite]">
            <input
              class="form-control"
              v-model="form.title"
              type="text"
              placeholder="Título"
              @keyup="syncEdit"
            />
            <span
              id="caculate"
              @click="calculateCost"
              class="flex items-center  justify-center mb-4 px-6 rounded-md h-[40px] font-bold cursor-pointer bg-yellow-400"
            >
              <span class="whitespace-nowrap">{{ cost }}</span>
            </span>
          </div>
          <div class="mb-4 w-full" v-if="sites[currentSite]">
            <!-- <ckeditor
              class="w-full"
              :editor="editor"
              v-model="form.content"
              :config="editorConfig"
              ></ckeditor> -->
              <quill-editor class="w-full form-control" v-model:content="form.content" contentType="html" @update:content="updateEditorContent" />
          </div>
          <div class="flex justify-start gap-8 text-[12px]">
            <label
              id="original-post"
              class="font-bold cursor-pointer text-red-500 mb-4 flex items-center gap-2 justify-start"
            >
              <input
                @change="saveData"
                type="checkbox"
                v-model="usarOriginal"
                :value="true"
              />
              POSTAR ORIGINAL
            </label>
            <label
              id="draft-post"
              class="font-bold cursor-pointer text-blue-500 mb-4 flex items-center gap-2 justify-start"
            >
              <input
                @change="saveData"
                type="checkbox"
                v-model="draft"
                :value="1"
              />
              RASCUNHO
            </label>
            <label
              id="update-post"
              class="font-bold cursor-pointer text-blue-500 mb-4 flex items-center gap-2 justify-start"
            >
              <input
                @change="saveData"
                type="checkbox"
                v-model="update_post"
                :value="true"
              />
              ATUALIZAR POST
            </label>
          </div>
          <credits-component
            v-if="!me.credits || me.credits <= 0"
          ></credits-component>
          <div class="w-full flex gap-4 flex-col md:flex-row items-center justify-between">
            <button
            vif="me.credits > 0"
            @click="postToActive"
            type="button"
            class="bg-green-500 w-full md:w-auto h-10 py-1 px-6 mt-0 text-white p-0 uppercase text-[14px] font-bold rounded-md"
            >
              Publicar no site selecionado
            </button>
            <button
            vif="me.credits > 0"
            @click="generateIA"
            type="button"
            class="bg-blue-500 w-full md:w-auto h-10 py-1 pr-1 pl-4 mt-0 text-white p-0 uppercase text-[14px] font-bold rounded-md"
            >
              Gerar novo conteúdo com IA <span class="bg-yellow-400 text-black py-2 px-4 ml-2 rounded-md">{{ cost }}</span>
            </button>
          </div>
        </div>
        <div v-if="!isMobile || (isMobile && view == 2)" class="col-right mb-auto">
          <div class="date mb-3">
            <label class="font-bold">Data do Post</label>
            <div class="dates" v-if="sites[currentSite]">
              <input
                class="form-control mb-3"
                ref="remote_date"
                v-model="remote_date"
                type="datetime-local"
              />
            </div>
          </div>
          <div class="categorias mb-auto">
            <label class="font-bold">Categorias</label>
            <div class="cats" v-if="sites[currentSite]">
              <label
                class="cat"
                v-for="cat in sites[currentSite].categories"
                :key="cat"
              >
                <input
                  type="checkbox"
                  @change="saveData"
                  v-model="sites[currentSite].selected_categories"
                  :value="cat.id"
                />{{ cat.name }}
              </label>
            </div>
          </div>
          <div class="image" v-if="sites[currentSite]">
            <label
              class="selector"
              :style="{
                backgroundImage: `url('${image}')`,
              }"
            >
              <input class="file" type="file" accept="image/*" @change="upload" />
              <font-awesome-icon icon="fa-solid fa-camera" />
            </label>
            <input
              type="text"
              class="form-control mt-3"
              v-model="sites[currentSite].form.caption"
              placeholder="Legenda da Imagem"
            />
          </div>
          <ins class="adsbygoogle"
            style="display:block"
            data-ad-client="ca-pub-2603389161912203"
            data-ad-slot="1148973949"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </div>
      </div>
      <div class="dialog" v-if="addsite">
        <form @submit.prevent="addSite">
          <input
            class="form-control"
            type="url"
            v-model="url_new_site"
            placeholder="URL do site"
          />
          <button class="btn" type="submit">CONECTAR</button
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button class="btn cancel" @click="addsite = false" type="button">
            CANCELAR
          </button>
        </form>
      </div>
      <div
        v-if="isMobile"
        class="flex flex-col bg-white absolute top-[80px] right-0"
      >
        <button
          @click="view = 1"
          :class="{ ['bg-green-600']: view == 1, ['text-white']: view == 1 }"
          class="py-2 px-3"
        >
          <font-awesome-icon icon="fa-network-wired" />
        </button>
        <button
          @click="view = 2"
          :class="{ ['bg-green-600']: view == 2, ['text-white']: view == 2 }"
          class="py-2 px-3 border-t-2"
        >
          <font-awesome-icon icon="fa-gear" />
        </button>
        <button
          @click="view = 3"
          :class="{ ['bg-green-600']: view == 3, ['text-white']: view == 3 }"
          class="py-2 px-3 border-t-2"
        >
          <font-awesome-icon icon="fa-align-left" />
        </button>
      </div>
    </div>
    <tutorial-component></tutorial-component>
  </div>
</template>

<script>
// @ is an alias to /src
import { version } from "../../package";
import LoginComponent from "../components/LoginComponent.vue";
import mitt from "mitt";
import SettingsComponent from "../components/SettingsComponent.vue";
import { decode } from "html-entities";
import LogoComponent from "../components/LogoComponent.vue";
import DownloadComponent from "../components/DownloadComponent.vue";
import CreditsComponent from "../components/CreditsComponent.vue";
import ProfileComponent from "../components/ProfileComponent.vue";
import ProgressComponent from "../components/ProgressComponent.vue";
import TutorialComponent from "../components/TutorialComponent.vue";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const emitter = mitt();
export default {
  name: "HomeView",
  components: {
    QuillEditor,
    LoginComponent,
    SettingsComponent,
    LogoComponent,
    DownloadComponent,
    CreditsComponent,
    ProfileComponent,
    ProgressComponent,
    TutorialComponent,
  },
  data() {
    return {
      editorConfig: {
        fileUpload: false,
        toolbar: [
              'undo', 'redo',
              '|',
              'heading',
              '|',
              'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
              '|',
              'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
              '|',
              'link', 'blockQuote', 'codeBlock',
              '|',
              'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
          ],
        removePlugins: ['ImageUpload', 'MediaEmbed', 'EasyImage', 'FileRepository', 'FileUpload'],
        shouldNotGroupWhenFull: true,
        language: "pt-br",
      },
      steps: [
        {
          attachTo: { element: "#sync" },
          content: { title: "Sincronize seus dados entre os dispositivos" },
        },
      ],
      search: 0,
      clipboardImage: "",
      windowWidth: window.innerWidth,
      blur: false,
      view: 1,
      saving: false,
      update_post: false,
      draft: 1,
      version,
      sites_history: [],
      tab: 0,
      currentPost: false,
      uselist: {},
      image: false,
      remote_search: "",
      remote_cat: "",
      remote_date: "",
      remote_date_search: "",
      remote_cats: [],
      focusText: false,
      fonte: "",
      fonte_selecionada: "",
      addsite: false,
      url_new_site: null,
      url: null,
      loading: false,
      caption: null,
      sites: {},
      posts: [],
      usarOriginal: false,
      fontePosts: "",
      autosaving: false,
      form: {
        cost: 0,
        post_id: 0,
        title: "",
        content: "",
        fonte: "",
        caption: "",
      },
      currentSite: false,
      activeSite: false,
      date: new Date().toLocaleDateString(),
      tracking: {},
      fonteCatText: "Categoria",
    };
  },
  watch: {
    fontePosts() {
      if (!this.search) return;
      try {
        let u = new URL(this.fontePosts);
        let _cat_url = `https://${u.hostname}/wp-json/wp/v2/categories/?_fields=id,name&per_page=100`;
        let cat_url = encodeURIComponent(_cat_url);
        this.$axios.get(`${this.proxyUrl}/?url=${cat_url}`).then((res) => {
          if (res.data.message) {
            // this.$toast.error(
            //   `Ops! Parece que ${this.fontePosts} não é um site WordPress :( \n\n${res.data.message}`
            // );
            return;
          }
          // this.$toast.success("Categorias carregadas");
          this.remote_cats = res.data;
        });
      } catch (e) {
        this.remote_cats = [];
      }
    },
    sites: {
      deep: true,
      handler() {
        this.$store.commit("setConfigs", this.$data);
      },
    },
    form: {
      deep: true,
      handler() {
        if (this.sites[this.currentSite]) {
          this.sites[this.currentSite].form = this.form;
        }
        this.$store.commit("setConfigs", this.$data);
      },
    },
  },
  mounted() {
    (adsbygoogle = window.adsbygoogle || []).push({});
    (adsbygoogle = window.adsbygoogle || []).push({});
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }

    this.$axios.get("sources").then((res) => (this.sites_history = res.data));

    emitter.on("finalizado", () => {
      let posts = Object.entries(this.uselist);
      if (posts.length) {
        this.postToAll(true);
      } else {
        this.uselist = {};
        this.tracking = {};
      }
    });
    this.loadData();
    document.onpaste = (event) => {
      var items = (
        event.clipboardData ||
        event.originalEvent.clipboardData ||
        window.clipboardData
      ).items;

      for (let index in items) {
        var item = items[index];
        if (item.kind === "file") {
          event.preventDefault();
          var blob = item.getAsFile();
          var reader = new FileReader();
          reader.onload = (event) => {
            this.clipboardImage = event.target.result;
            this.image = event.target.result;
            for (let i in this.posts) {
              if (this.currentPost.id != this.posts[i].id) {
                continue;
              }
              this.posts[i].replace_image = event.target.result;
            }
          };
          reader.readAsDataURL(blob);
          return;
        }
      }
    };
    if (this.sites[this.currentSite]) {
      this.getCategories(this.currentSite, this.sites[this.currentSite]);
    }
    window.addEventListener(
      "resize",
      () => (this.windowWidth = window.innerWidth)
    );
  },
  computed: {
    currentSource() {
      return (
        this.sites_history.filter((item) => item.url == this.fontePosts)[0] ??
        {}
      );
    },
    isMobile() {
      return this.windowWidth < 800;
    },
    configs() {
      return this.$store.state.configs;
    },
    me() {
      return this.$store.state.me;
    },
    cost() {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(this.form.cost || 0);
    },
    gtag() {
      return window.gtag;
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    proxyUrl() {
      return process.env.VUE_APP_PROXY_URL;
    },
    hasSelected() {
      for (var site in this.sites) {
        if (this.sites[site].postTo) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    updateEditorContent(delta) {
      if(delta.ops && delta.ops.length)
      this.form.content = delta.ops[0].insert
    },
    syncEdit() {
      for (let i in this.posts) {
        if (this.currentPost.id != this.posts[i].id) {
          continue;
        }
        if (this.form.title) {
          this.posts[i].replace_title = this.form.title;
        }
        if (this.form.content) {
          this.posts[i].replace_content = this.form.content;
        }
        if (this.form.fonte) {
          this.posts[i].replace_link = this.form.content;
        }
        this.currentPost = this.posts[i];
      }
    },
    previewPost(post) {
      this.currentPost = post;
      this.postThis(post, 0, 1);
    },
    saveData() {
      if (this.saving) {
        clearTimeout(this.saving);
      }
      this.saving = setTimeout(() => {
        let form = Object.assign({}, this.$data);
        delete form.windowWidth;
        delete form.isMobile;
        delete form.uselist;
        delete form.editor_settings;
        delete form.version;
        delete form.loading;
        delete form.autosaving;
        delete form.saving;
        this.$axios.post("/sync", form).then(() => {
          this.loading = false;
        });
      }, 1000);
    },
    loadData() {
      return this.$axios
        .get("/sync")
        .then((res) => {
          for (let prop in res.data) {
            if (prop == "version") continue;
            this[prop] = res.data[prop];
          }
          this.$store.commit("setConfigs", res.data);
          this.loading = false;
        })
        .then(() => {
          this.$forceUpdate();

          for (let site in this.sites) {
            this.getCount(site);
          }
        });
    },
    calculateCost() {
      return this.$axios
        .post("/tokens", {
          title: this.form.title,
          content: this.form.content,
        })
        .then((res) => {
          this.form.cost = res.data.total_cost;
        });
    },
    addSource() {
      let url = prompt("Insira a URL do site");
      try {
        const fccUrl = new URL(url);
        if (this.sites_history[url]) return;
        this.$axios.post("add-source", { url }).then((res) => {
          this.sites_history = res.data;
          this.fontePosts = url;
          // this.$toast.success("Fonte adicionada");
        });
      } catch (err) {
        this.$toast.error("URL inválida");
      }
    },
    removeSource() {
      this.$axios.post("remove-source", { url: this.fontePosts }).then(() => {
        this.$toast.success("Fonte removida");
        this.fontePosts = "";
      });
    },
    importPost() {
      this.$axios.post(`${this.proxyUrl}/content`, { url: this.form.fonte }).then(res => {
        let post = res.data;
        this.currentPost = post;
        this.postThis(post, 0, 1);
      });
    },
    isWeekend(data) {
      var today = data;
      var dayOfWeek = today.getDay();

      // Check if it's Saturday (6) or Sunday (0)
      return dayOfWeek === 0 || dayOfWeek === 6;
    },
    diasDaSemanaAbreviado(data) {
      // var opcoes = { weekday: "short", locale: "pt-BR" };
      // return data.toLocaleDateString("pt-BR", opcoes);
      return data.getDate().toString().padStart(2, "0");
    },
    diaKey(date) {
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      var curDate = `${date.getFullYear()}-${month}-${day}`;
      return curDate;
    },
    ultimos7Dias() {
      var hoje = new Date(); // Obtém a data atual
      var arrayUltimos7Dias = [];

      // Itera para obter as datas dos últimos 7 dias
      for (var i = 0; i < 7; i++) {
        var data = new Date(hoje); // Cria uma nova data com a data atual
        data.setDate(hoje.getDate() - i); // Subtrai o número de dias necessários
        arrayUltimos7Dias.push(data); // Adiciona a data ao array
      }

      return arrayUltimos7Dias.reverse(); // Retorna o array de datas
    },
    addToList(post) {
      if (!this.uselist[post.id]) {
        this.uselist[post.id] = post;
      } else {
        delete this.uselist[post.id];
      }
      this.saveData();
    },
    validUrl(urlString) {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(urlString);
    },
    async postThis(post, single, preview) {
      this.search = 0;
      if (!this.currentSource?.is_wordpress) {
        return new Promise(async (resolve, reject) => {
          await this.$axios
          .post(`${this.proxyUrl}/content`, { url: post.link })
          .then((res) => {
              let post = res.data
              if (post) {
                this.tracking[post.link];
                if (post.link) {
                  this.form.fonte = post.link;
                }
                if (post.replace_title) {
                  this.form.title = decode(post.replace_title);
                } else {
                  if (post.title && post.title.rendered) {
                    this.form.title = decode(post.title.rendered);
                  }
                }
                if (post.replace_content) {
                  this.form.content = decode(post.replace_content);
                } else {
                  if (post.content && post.content.rendered) {
                    this.form.content = decode(post.content.rendered);
                  }
                }
                if (post.replace_link) {
                  this.form.fonte = decode(post.replace_link);
                } else {
                  if (post.link) {
                    this.form.fonte = post.link;
                  }
                }
                let tracking_key = `${this.currentPost} ${this.form.fonte}`;
                if (this.tracking[tracking_key]) {
                  return;
                }
                this.tracking[tracking_key] = true;
                this.form.post_id = post.id;
                this.calculateCost();
                this.tab = 0;
                if (single) {
                  this.uselist = { [post.id]: post };
                }
                if (post.replace_image) {
                  this.getImage(post.replace_image).then(() => {
                    resolve();
                    this.$forceUpdate();
                  });
                } else {
                  if (post._embedded && post._embedded["wp:featuredmedia"]) {
                    this.getImage(
                      post._embedded["wp:featuredmedia"][0].source_url
                    ).then(() => {
                      resolve();
                      this.$forceUpdate();
                    });
                  } else {
                    if (
                      post.better_featured_image &&
                      post.better_featured_image.source_url
                    ) {
                      this.getImage(post.better_featured_image.source_url).then(
                        () => {
                          resolve();
                          this.$forceUpdate();
                        }
                      );
                    }
                  }
                }
                this.sites[this.currentSite].form = this.form;
              } else {
                resolve();
              }
            });
        });
      }
      return new Promise((resolve, reject) => {
        if (post) {
          this.tracking[post.link];
          if (post.link) {
            this.form.fonte = post.link;
          }
          if (post.replace_title) {
            this.form.title = decode(post.replace_title);
          } else {
            if (post.title && post.title.rendered) {
              this.form.title = decode(post.title.rendered);
            }
          }
          if (post.replace_content) {
            this.form.content = decode(post.replace_content);
          } else {
            if (post.content && post.content.rendered) {
              this.form.content = decode(post.content.rendered);
            }
          }
          if (post.replace_link) {
            this.form.fonte = decode(post.replace_link);
          } else {
            if (post.link) {
              this.form.fonte = post.link;
            }
          }
          let tracking_key = `${this.currentPost} ${this.form.fonte}`;
          if (this.tracking[tracking_key]) {
            return;
          }
          this.tracking[tracking_key] = true;
          this.form.post_id = post.id;
          this.calculateCost();
          this.tab = 0;
          if (single) {
            this.uselist = { [post.id]: post };
          }
          if (post.replace_image) {
            this.getImage(post.replace_image).then(() => {
              resolve();
              this.$forceUpdate();
            });
          } else {
            if (post._embedded && post._embedded["wp:featuredmedia"]) {
              this.getImage(
                post._embedded["wp:featuredmedia"][0].source_url
              ).then(() => {
                resolve();
                this.$forceUpdate();
              });
            } else {
              if (
                post.better_featured_image &&
                post.better_featured_image.source_url
              ) {
                this.getImage(post.better_featured_image.source_url).then(
                  () => {
                    resolve();
                    this.$forceUpdate();
                  }
                );
              }
            }
          }
          this.sites[this.currentSite].form = this.form;
        } else {
          resolve();
        }
      });
    },
    getImage(url) {
      this.image = url;
      return new Promise((resolve) => resolve());
    },
    buscarPosts() {
      if (!this.fontePosts) return;
      if (!this.fontePosts.match(/http/gi))
        this.fontePosts = `https://${this.fontePosts}`;
      this.uselist = {};
      if (this.currentSource?.is_wordpress) {
        this.$wordpressSearch(this);
      } else {
        this.$rssSearch(this);
      }
    },
    postToActive() {
      this.genTitle().then(() => {
        this.genContent().then(() => {
          this.save()
            .then()
            .catch(() => {});
        });
      });
    },
    postToAll(stop) {
      let posts = Object.entries(this.uselist);
      if (!stop && !posts.length && this.form.title && this.form.content) {
        this.currentPost = {
          id: 0,
          link: this.form.fonte,
          title: {
            rendered: this.form.title,
          },
          content: {
            rendered: this.form.content,
          },
          _embedded: {
            "wp:featuredmedia": [
              {
                source_url: this.image,
              },
            ],
          },
        };
        posts.push(this.currentPost);
      }
      if (posts.length) {
        this.currentPost = posts.pop()[1];
        this.postThis(this.currentPost)
          .then(() => {
            setTimeout(() => {
              var sites = [];
              var _sites = [];
              for (var site in this.sites) {
                _sites.push(site);
                if (this.sites[site].postTo) {
                  sites.push(site);
                }
              }
              if (sites.length == 0) {
                sites = _sites;
              }
              this._postToAll(sites);
            }, 1000);
          })
          .catch();
      }
    },
    _postToAll(sites) {
      if (!sites.length) return;
      this.currentSite = sites.pop();
      this.genTitle().then(() => {
        this.genContent().then(() => {
          this.save()
            .then(() => {
              if (sites.length > 0) {
                this._postToAll(sites);
                emitter.emit("posted");
              } else {
                emitter.emit("finalizado");
              }
            })
            .catch(() => {});
        });
      });
    },
    updateCount(sites) {
      let current = sites.pop();
      if (current) {
        this.getCount(current).then(() => {
          this.updateCount(sites);
        });
      }
    },
    changecolor(event) {
      this.sites[this.currentSite].color = event.target.value;
      this.$forceUpdate();
      this.saveData();
      this.gtag("event", "change_color", {
        site: this.currentSite,
        color: event.target.value,
      });
    },
    exportsites() {
      const jsonString = JSON.stringify(this.$data, null, 2); // The null and 2 are for formatting the JSON
      const blob = new Blob([jsonString], { type: "application/json" });
      const downloadLink = document.createElement("a");
      downloadLink.download = `iaxpress-${new Date()
        .toLocaleString()
        .replace(/([^\d]+)/gi, "")}.json`; // Filename for the downloaded file
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.click();
      this.gtag("event", "export_sites");
    },
    importsites() {
      var input = document.createElement("input");
      input.type = "file";
      input.onchange = (event) => {
        const file = event.target.files[0];
        if (!file) {
          return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
          const contents = event.target.result;
          try {
            const jsonData = JSON.parse(contents);
            for (let prop in jsonData) {
              this[prop] = jsonData[prop];
            }
            this.$store.commit("setConfigs", jsonData);
            setTimeout(this.saveData, 2000);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        };
        reader.readAsText(file);
      };
      input.accept = ".json";
      input.click();
      this.gtag("event", "import_sites");
    },
    insertTextAtCursor(textToInsert) {
      var textarea = this.$refs.textarea;
      var startPos = textarea.selectionStart;
      var endPos = textarea.selectionEnd;

      var textBefore = textarea.value.substring(0, startPos);
      var textAfter = textarea.value.substring(endPos, textarea.value.length);

      textarea.value = textBefore + textToInsert + textAfter;

      // Reposiciona o cursor após o texto inserido
      var newCursorPosition = startPos + textToInsert.length;
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
    },
    isImageURL(url) {
      this.gtag("event", "paste_image_url", { site: this.currentSite });
      return /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(url);
    },
    isVideoURL(url) {
      this.gtag("event", "paste_video_url", { site: this.currentSite });
      return /\.(mp4|webm|ogg)$/i.test(url);
    },
    isAudioURL(url) {
      this.gtag("event", "paste_audio_url", { site: this.currentSite });
      return /\.(mp3)$/i.test(url);
    },
    upload(e) {
      let [file] = e.target.files;
      if (file) {
        var reader = new FileReader();
        reader.onload = (event) => {
          this.image = event.target.result;
          this.gtag("event", "upload", { site: this.currentSite });
        }; // data url!
        reader.readAsDataURL(file);
      }
    },
    getCount(site) {
      let now = new Date();
      let sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(now.getDate() - 7);
      let after = `${sevenDaysAgo.getFullYear()}-${(sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, 0)}-${sevenDaysAgo
        .getDate()
        .toString()
        .padStart(2, 0)}T00:00:00.000Z`.toString();
      let before = `${now.getFullYear()}-${(now.getMonth() + 1)
        .toString()
        .padStart(2, 0)}-${now
        .getDate()
        .toString()
        .padStart(2, 0)}T23:59:59.000Z`.toString();
      let url = `${site}wp-json/wp/v2/posts?_fields=date&per_page=50&before=${before}&after=${after}&count=1`;
      let target = encodeURI(url);
      return this.$axios.get(`${this.proxyUrl}?url=${target}`).then((res) => {
        try {
          // this.sites[site].counter_total = res.data.length;
          let posts = res.data;
          this.sites[site].counter = {};
          for (let i in posts) {
            let post = posts[i];
            let curDate = post.date.split("T").shift();
            if (!this.sites[site].counter[curDate]) {
              this.sites[site].counter[curDate] = 0;
            }
            this.sites[site].counter[curDate] =
              this.sites[site].counter[curDate] + 1;
          }
          this.$forceUpdate();
        } catch (error) {
          this.gtag("event", "error", { message: error.message });
        }
        this.gtag("event", "count", { site: site });
      });
    },
    getCategories(site, config) {
      this.currentSite = site;
      config.active = !config.active;
      this.getCount(site);
      let target = encodeURI(`${site}wp-json/wp/v2/categories?_fields=name,id`);
      this.$axios.get(`${this.proxyUrl}?url=${target}`).then((res) => {
        this.sites[site].categories = res.data;
        this.gtag("event", "get_categories", { site: site });
        this.saveData();
      });
    },
    generateIA() {
      this.loading = true
      this.$axios.post('ia', {title: this.form.title, content:this.form.content}).then(res => {
        this.form.title = res.data.title
        this.form.content = res.data.content
        this.sites[this.currentSite].form.title = res.data.title
        this.sites[this.currentSite].form.content = res.data.content
        this.saveData()
        this.loading = false
        this.$axios.get("/me").then((res) => {
          this.$store.commit("setMe", res.data);
        });
        this.$forceUpdate();
      }).catch(() => { this.loading = false })
    },
    genTitle() {
      this.sites[this.currentSite].form.title = this.form.title;
      return new Promise((resolve) => resolve());
    },
    genContent() {
      this.sites[this.currentSite].form.content = this.form.content;
      return new Promise((resolve) => resolve());
    },
    verifyFonte(site) {
      if (!this.sites[site]) return;
      let form = { fonte: this.form.fonte };
      form.site = site;
      this.gtag("event", "verify_fonte", { site: site });
      return this.$axios.post(`${this.apiUrl}/verify-fonte`, form);
    },
    save() {
      this.loading = true;
      let form = {};
      form.categories = this.sites[this.currentSite].selected_categories;
      form.title = this.form.title;
      form.content = this.form.content;
      form.caption = this.form.caption;
      form.post_id = this.form.post_id;
      form.fonte = this.form.fonte;
      form.status = this.draft ? 'draft' : 'publish';
      form.image = this.image;
      form.date = this.remote_date;
      form.site_url = `${this.currentSite}wp-json/iaxpress/v1/create-post`;
      form.usarOriginal = this.usarOriginal;
      form.update_post = this.update_post;
      form.token = this.sites[this.currentSite].token;
      form.token_key = this.sites[this.currentSite].token_key;
      let target = btoa(
        `${this.currentSite}wp-json/iaxpress/v1/create-post`
      );
      this.gtag("event", "save", { site: this.currentSite, post: form.title });
      delete this.uselist[form.post_id];
      return new Promise((resolve, reject) => {
        this.$axios
          .post(`${this.apiUrl}/save-post/${target}`, form)
          .then((res) => {
            this.loading = false;
            this.getCount(this.currentSite);
            this.saveData();
            if (res.data.success == false) {
              alert(res.data.message);
              reject();
            }
            this.$axios
              .get("/me")
              .then((res) => {
                this.$store.commit("setMe", res.data);
              })
              .catch(() => {
                if (localStorage.user) {
                  delete localStorage.user;
                  location.reload();
                }
              });
            resolve();
          })
          .catch(() => {
            this.loading = false;
            reject();
          });
      });
    },
    getDomain(url) {
      return new URL(url).host;
    },
    removeSite(site) {
      delete this.sites[site];
      this.saveData();
    },
    addSite() {
      let url = this.url_new_site;
      if (url) {
        localStorage.connecting = url;
        this.gtag("event", "add_site", { site: url });
        setTimeout(
          () =>
            (location.href = `${url}/wp-admin/admin-ajax.php?action=iaxpress_generate_token`),
          1000
        );
      }
    },
    decode(str) {
      return decode(str);
    },
    connect(site) {
      localStorage.connecting = site;
      this.gtag("event", "connect", { site: site });
      location.href = `${site}wp-admin/admin-ajax.php?action=iaxpress_generate_token`;
    },
  },
};
</script>
