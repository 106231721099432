<template>
    <div class="pagination flex gap-4 justify-center items-center text-[14px] py-1 px-3 rounded-sm">
      <button
        v-if="currentPage > 1"
        @click="changePage(currentPage - 1)"
      >
        Previous
      </button>
  
      <button
        v-for="page in visiblePages"
        :key="page"
        :class="{ 'bg-green-400': currentPage === page }"
        class="w-[32px] h-[32px] bg-gray-200"
        @click="changePage(page)"
      >
        {{ page }}
      </button>
  
  
      <button
        v-if="currentPage < totalPages"
        @click="changePage(currentPage + 1)"
      >
        Next
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      totalPages: {
        type: Number,
        required: true,
      },
      currentPage: {
        type: Number,
        required: true,
      },
    },
    computed: {
      visiblePages() {
        const pages = [];
        const start = Math.max(1, this.currentPage - 5);
        const end = Math.min(this.totalPages, this.currentPage + 5);
  
        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
  
        return pages;
      },
    },
    methods: {
      changePage(page) {
        this.$emit("update:currentPage", page);
      },
    },
  };
  </script>
  
  <style>
  .pagination {
    display: flex;
    gap: 5px;
  }
  button.active {
    font-weight: bold;
    background-color: #007bff;
    color: white;
  }
  </style>
  